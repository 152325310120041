import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react';
import React from 'react';
import './NotesCard.scss';

interface NotesCardProps {
    isCenter: boolean;
    type?: 'success' | 'warning' | 'danger';
    // content?: string;
    title?: string;
    cardColor?: string;
    children: React.ReactNode
}

const NotesCard: React.FC<NotesCardProps> = ({ isCenter, type, title, cardColor, children }) => {
    return (
        <>
            {isCenter &&
                <IonRow id="note-card-row">
                    <IonCol size="12" size-xl="9">
                        <IonCard class={(title ? "note-with-title-card" : "note-card") + " bl-" + type + " "} >
                            {title && <IonCardHeader class="ion-text-uppercase" color="primary">
                                <IonCardTitle>{title}</IonCardTitle>
                            </IonCardHeader>
                            }
                            {
                                <IonCardContent class="ion-text-justify">
                                    {children}
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            }
            {!isCenter &&
                <IonCard class={(title ? "note-with-title-card" : "note-card") + " bl-" + type}>
                    {title && <IonCardHeader class="ion-text-uppercase" color="primary">
                        <IonCardTitle>{title}</IonCardTitle>
                    </IonCardHeader>
                    }
                    {
                        <IonCardContent class="ion-text-justify">
                            {children}
                        </IonCardContent>
                    }
                </IonCard>
            }
        </>
    )
}

export default NotesCard;