import { IonAlert, IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem } from '@ionic/react';
import { addCircleOutline, checkmarkCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import { Tag } from '../../models/Tag';

interface TagListItemProps {
    tag: Tag;
    isUserTag: boolean;
    userTags: string[];
    OnaddOrRemoveTagWrapper: (tagId: string, isAdd: boolean) => void;
    isAuthenticated: boolean;
    history: any;
    handleShowToast: (message: string, isTagAddedFlag: boolean) => void;
}

const TagListItem: React.FC<TagListItemProps> = ({ history, OnaddOrRemoveTagWrapper, tag, isUserTag, userTags, isAuthenticated, handleShowToast }) => {
    const [showAlert, setShowAlert] = useState(false);
    const addOrRemoveTag = async (isAdd: boolean) => {
        if (isAuthenticated) {
            OnaddOrRemoveTagWrapper(tag.id, isAdd);
            const toastMessage = isAdd ? `${tag.id} added to your favourite.` : `${tag.id} is removed.`
            handleShowToast(toastMessage, isAdd);
        } else {
            setShowAlert(true);
        }
    };

    return (
        <>
            <IonCard className="ionic-component-card" mode="ios" onClick={isUserTag ? () => addOrRemoveTag(false) : () => addOrRemoveTag(true)}>
                <IonCardHeader>
                    <IonItem detail={false} lines="none">
                        <IonAvatar slot="start">
                            <img src={process.env.PUBLIC_URL + '/assets/img/tags/card-icons/component-card-icon.png'} alt="JS" />
                        </IonAvatar>
                        <IonButton fill="clear" size="large" slot="end" shape="round" color={isUserTag ? "primary" : "medium"}>
                            {!isUserTag && <IonIcon icon={addCircleOutline} slot="icon-only" />}
                            {isUserTag && <IonIcon icon={checkmarkCircle} slot="icon-only" />}
                        </IonButton>
                    </IonItem>
                    <IonItem detail={false} lines="none">
                        <IonCardTitle>
                            {tag.name}
                        </IonCardTitle>
                    </IonItem>
                </IonCardHeader>
                <IonCardContent>
                    <IonItem detail={false} lines="none">
                        <p>{tag.subTitle}</p>
                    </IonItem>
                </IonCardContent>
            </IonCard>
            <IonAlert
                isOpen={showAlert}
                cssClass='my-custom-alert error'
                mode="ios"
                header="You are not logged in. Do you want to sign in ?"
                buttons={[
                    'Cancel',
                    {
                        text: 'Continue to Login',
                        handler: async () => {
                            history.push('/login', { direction: 'none' });
                        }
                    }
                ]}
                onDidDismiss={() => setShowAlert(false)}
            />
        </>
    )
}

export default React.memo(TagListItem);