import { IonAvatar, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRouterLink, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { FunctionComponent } from "react";
import { Bounce, Fade } from "react-awesome-reveal";
import HelmetWrapper from "../../components/Core/Common/HelmetWrapper";
import { AppConstants, PageNameEnum } from "../../constants/AppConstants";
import './Home.scss';
interface HomeProps {

}

const Home: FunctionComponent<HomeProps> = () => {

    const title = 'FireAlgo';
    let metaTag = AppConstants.getMetaTag(PageNameEnum.home);
    return (
        <IonPage id="home-page">
            <HelmetWrapper metaTag={metaTag} />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    {/* <Link to="/"> */}
                    <IonRouterLink routerLink="/">

                        <IonChip >
                            <IonAvatar>
                                <img src="assets/icons/firealgo-144.png" alt="fa" />
                            </IonAvatar>
                            <IonTitle>{title}</IonTitle>

                        </IonChip>
                    </IonRouterLink>

                    {/* </Link> */}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <Fade direction="up" triggerOnce>
                        <IonRow class="first-row">
                            <IonCol size="12" size-sm="6" class="landing__content" >
                                {/* <span>Window size: {width} x {height}</span>; */}
                                <h1>Exeriance the amazing coding with easy on firealgo</h1>
                                <p className="intro">
                                    <span className="darken">Firealgo</span> is a place where you can learn and grow your coding skill. You get more. Easy to Expert. You would start loving your own code.
                                </p>
                            </IonCol>
                            <IonCol size="12" size-sm="6" >
                                <img src="assets/img/home/hero-ionic-react@1x.png" alt="img" className="slide-image" />
                            </IonCol>
                        </IonRow>
                    </Fade>
                    <section id="build-with-web">
                        <Fade direction="up" triggerOnce>
                            <IonRow class="landing__content">
                                <IonCol size="12" size-sm="6">
                                    <h2> For one who love coding. For full-stack developer</h2>
                                </IonCol>
                                <IonCol size="12" size-sm="6" >
                                    <p className="intro">
                                        This is an open platform for all kind of developer. Experts like you share there amazing experience with us and we share to whole world.
                                    </p>
                                </IonCol>
                            </IonRow>

                        </Fade>

                    </section>
                </IonGrid>
                <IonGrid class="plug-image-grid">
                    <Fade direction="up" triggerOnce>
                        <IonRow>
                            <IonCol size="12" size-lg="6" id="plug-img">
                                <img src="assets/img/home/midnight-plug.png" alt="img" className="slide-image" />
                            </IonCol>
                            <IonCol size="12" size-lg="6" id="plug-desc">
                                <h3>Share your own article</h3>
                                <p>
                                    You can share and help other people who are asking a help. Your profile rating will get increased.
                                </p>
                            </IonCol>
                        </IonRow>
                    </Fade>
                    <Fade direction="up" triggerOnce>
                        <IonRow class="ion-justify-content-center center-text-row">
                            <Bounce>
                                <h2> Aren't you just a little curious?</h2>
                            </Bounce>
                        </IonRow>
                    </Fade>
                </IonGrid>

                <IonRow class="fa-footer">
                    <IonCol class="container">
                        <IonRow class="logo">
                            <img src="assets/icons/firealgo-144.png" alt="img" />
                        </IonRow>
                        <IonRow class="links-container">
                            <div className="mr-3 mr-sm-5 u">
                                <a href="/#" className="el em az ba bb bc bd be bf bg en eo bj ep eq"><h4 className="at au av jv">Get Started</h4></a>
                            </div>
                            <div className="mr-3 mr-sm-5 u">
                                <a href="/#" className="el em az ba bb bc bd be bf bg en eo bj ep eq"><h4 className="at au av jv">Subscribe</h4></a>
                            </div>
                            <div className="mr-3 mr-sm-5 d-flex ew">
                                <h4 className="text-muted mr-2">Have an account?</h4>
                                <span><a href="/#" className="el em az ba bb bc bd be bf bg en eo bj ep eq"><h4 className="kb u">Sign In</h4></a></span>
                            </div>

                        </IonRow>
                        {/* <hr /> */}
                        <IonRow class="copyright">
                            <h4 className="fs-5 fw-light">© 2020 firealgo.com</h4>
                        </IonRow>

                    </IonCol>
                </IonRow>

            </IonContent>

        </IonPage>
    );
}

export default Home;