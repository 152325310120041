import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { addTagsToUserApi, setItemToStorage } from '../dataApi';
import { UserState } from './state';

const initialState: UserState = {
    isLoggedin: true,
    loading: false,
    preference: {
        hasSeenTutorial: false,
        darkMode: false
    },
    tags: []
}

export const addOrRemoveTagWrapper = (tagId: string, isAdd: boolean): AppThunk =>
    async (dispatch, getState) => {
        let tags = selectUser(getState()).tags;
        if (isAdd) {
            tags = [...tags, tagId];
        } else {
            tags = [...tags.filter(x => x !== tagId)]
        }
        const result = await addTagsToUserApi(tags);
        console.log(result)
        if (isAdd) {
            dispatch(addTag(tagId));
        } else {
            dispatch(removeTag(tagId));
        }
    }

export const setHasSeenTutorial = createAsyncThunk(
    'user/setHasSeenTutorial',
    async (hasSeenTutorial: boolean) => {
        setItemToStorage('hasSeenTutorial', hasSeenTutorial + "");
        // The value we return becomes the `fulfilled` action payload
        return hasSeenTutorial;
    }
);

// export const addOrRemoveTagWrapper = createAsyncThunk(
//     'user/setHasSeenTutorial',
//     async (hasSeenTutorial: boolean) => {
//         setItemToStorage('hasSeenTutorial', hasSeenTutorial + "");
//         // The value we return becomes the `fulfilled` action payload
//         return hasSeenTutorial;
//     }
// );
// export const selectCount = (state: RootState) => state.

// export const addOrRemoveTagWrapper =
//     (amount: number): AppThunk =>
//         (dispatch, getState) => {
//             const currentValue = selectCount(getState());
//             if (currentValue % 2 === 1) {
//                 dispatch(incrementByAmount(amount));
//             }
//         };



export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        setDarkMode: (state, action: PayloadAction<boolean>) => {
            state.preference.darkMode = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedin = action.payload;
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setUserTags: (state, action: PayloadAction<string[]>) => {
            state.tags = action.payload;
        },
        addTag: (state, action: PayloadAction<string>) => {
            state.tags.push(action.payload)
        },
        removeTag: (state, action: PayloadAction<string>) => {
            state.tags = state.tags.filter(x => x !== action.payload)
        },
        setIdToken: (state, action: PayloadAction<string>) => {
            state.idToken = action.payload
        },
        setIsLoggedin: (state, action: PayloadAction<boolean>) => {
            state.isLoggedin = action.payload
        },
        setData: (state, action: PayloadAction<Partial<UserState>>) => {
            Object.assign(state, action.payload)
        },



    },
    extraReducers: (builder) => {
        builder
            .addCase(setHasSeenTutorial.fulfilled, (state, action) => {
                state.preference.hasSeenTutorial = action.payload;
            });

    }

})
export const { addTag, removeTag, setIsLoggedIn, setUsername, setData, setDarkMode, setIdToken, setIsLoggedin, setUserTags } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;

