import { HttpClient } from './httpClient';
// import { User } from './types';
import * as commonService from './commonService';
import { AppConstants } from '../../constants/AppConstants';
import { Topic } from '../../models/Topic';
import { Tag } from '../../models/Tag';
import { isLocalhost } from '../../util/commonUtil';
/**
 * This is the common service for all axios api call.
 * this is a singleton class.
 * Use:  get the class instance using getInstance() and use the api method u need
 */
export default class MainApi extends HttpClient {
    private static instance: MainApi;
    public static getInstance(): MainApi {
        if (!MainApi.instance) {
            if (isLocalhost && !AppConstants.useLocalFunction && AppConstants.environment.prodApiBaseUrl) {
                MainApi.instance = new MainApi(AppConstants.environment.prodApiBaseUrl);
            } else {
                MainApi.instance = new MainApi(AppConstants.apiBaseUrl);
            }
        }
        return MainApi.instance;
    }
    // private constructor for singleton class.
    private constructor(url: string) {
        super(url);
    }

    public getTopicList = async () => {
        if (commonService.getIsOnline()) {
            return this.instance.get<Topic[]>('/topic', {
                timeout: 5000,
                headers: {
                    "name": "deepak",
                    "Autherization": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjUzNmRhZWFiZjhkZDY1ZDRkZTIxZTgyNGI4OTlhMWYzZGEyZjg5NTgiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiZmlyZWFsZ28gLXRlY2giLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2cwdTdOSlNYem9wQjFsSVFtS3BNZGZNTWdCaUdsaXBNOWYwV2pTPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2ZpcmVhbGdvLWRldi1iNzk2YiIsImF1ZCI6ImZpcmVhbGdvLWRldi1iNzk2YiIsImF1dGhfdGltZSI6MTYyMDcyNjUzMCwidXNlcl9pZCI6Ik9tandleU9JOXRSMjNONXExN29Vbm43NGtIODMiLCJzdWIiOiJPbWp3ZXlPSTl0UjIzTjVxMTdvVW5uNzRrSDgzIiwiaWF0IjoxNjIwOTAyODI3LCJleHAiOjE2MjA5MDY0MjcsImVtYWlsIjoiZGVlcGFrLmd1cHRhLnNreUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwNTIxNzIxODE1NjI4NjAzNjAwOCJdLCJlbWFpbCI6WyJkZWVwYWsuZ3VwdGEuc2t5QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.h1seo-OxTdvpa4D7DRtZUUw0F1YkUB_7-6YCwqd-bYJhg7zHXynY-oCBKVVU0gZ36Qg81sXQJ7EcamFMypvU6OSbPIBBaBeFiQqhqYUsmGcBBmeEiZplhpUuAYG576cDLk50dt0AXR4L8ZZBBYi65Pen1_n2m1mRIP3O9CpAS-UpynHlx3pUZy4sp2rN3UX1dOA-R5iRzQFPcY62oLPGs_bKKjWw3yKYmWmuy5_hNHxj7hWT6JnPKJv-jfPvtn9olkFsLFL6QWmiYH7iSBNxjKy-p2JLXObwESH4t4zlicZiBRadj79nk5w0Z5-C4T74-mYAFMTXYNVgpIzzKs41_Q"
                },

            });
        } else {
            commonService.getInternetFailedError();
        }
    }

    public getTagList = async () => this.getEntityList<Tag>("/tag");
    public getQuizList = async () => this.getEntityList<Tag>("/quiz");

    private async getEntityList<E>(url: string) {
        if (commonService.getIsOnline()) {
            return this.instance.get<E[]>('/tag', {
                timeout: 5000,
            });
        } else {
            commonService.getInternetFailedError();
        }
    }

    // public getTopic2 = (id: string) => this.instance.get<any>(`/topic/id/${id}`, { timeout: 5000 });
    public getTopic = async (id: string) => {
        if (commonService.getIsOnline()) {
            // below commented code I will use if needed.
            /* const response = await Promise.all([
                this.instance.get<any[]>(`/topic/id/${id}`, { timeout: 5000 })
            ]);
            console.log('response', response[0]); */
            return this.instance.get<Topic[]>(`/topic/id/${id}`, { timeout: 5000 });
        } else {
            commonService.getInternetFailedError();
        }
    }
}
// export const mainApiInstance = new MainApi();