import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCrG2A4fSLKShjCJ-VrsFu9fAA4u0Z0_64",
    authDomain: "firealgo-48da7.firebaseapp.com",
    projectId: "firealgo-48da7",
    storageBucket: "firealgo-48da7.appspot.com",
    messagingSenderId: "346423974014",
    appId: "1:346423974014:web:87278e24bed5a4c78ce02f",
    measurementId: "G-RBR6TVXZK8"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage, app, analytics };