import React from 'react';
import { Quiz } from '../../../models/Quiz';
import { IonCard, IonCardHeader, IonItem, IonAvatar, IonCardContent, IonCardTitle } from '@ionic/react';

interface QuizItemProps {
    quiz: Quiz;
}
const QuizItem: React.FC<QuizItemProps> = ({ quiz }) => {
    return (
        <>
            <IonCard className="ionic-component-card" mode="ios" routerLink={`/quiz/${quiz.id}`}>
                <IonCardHeader>
                    <IonItem detail={false} lines="none">
                        <IonAvatar slot="start">
                            <img src={process.env.PUBLIC_URL + '/assets/img/tags/card-icons/component-card-icon.png'} alt="JS" />
                        </IonAvatar>
                    </IonItem>

                    <IonItem detail={false} lines="none">
                        <IonCardTitle>
                            {quiz.name}
                        </IonCardTitle>
                    </IonItem>
                </IonCardHeader>
                <IonCardContent>
                    <IonItem detail={false} lines="none">
                        <p>{"Click & Start the Quiz on" + quiz.name + " "}</p>
                    </IonItem>
                </IonCardContent>
            </IonCard>
        </>
    )
}

export default QuizItem;