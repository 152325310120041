
import { envDevelopment, envStage, envProd, Environment } from './AppSettings';
export enum PlatformEnum { width, height, url, isLandscape, isPortrait, isDesktop, isMobile, pwa, android }
export enum PageNameEnum { home, topicList, topicDetail, login, tags, quizList }
// META TAGS :: START

export interface MetaTag {
    title: string;
    description: string;
    url: string;
    pageName: PageNameEnum;
};

export const REGION = "asia-south1";
export class AppConstants {
    public static get appBaseUrl(): string {
        if (process.env.NODE_ENV === "production") {
            return "https://www.firealgo.com";
        } else {
            return "http://localhost:4200/api";
        }
    }
    /**
     * return the configurations based on the NODE_ENV
     * @Note 01: there is one more imp variable => window.location.hostname
     * this can also be used to determine the env.
     * For example see, serviceWorker.ts file
     * @Note 02: when we run our react application on local using npm start , the value for process.env.NODE_ENV will be "development"
     * On creating build and deploy the value will be "production."
     */
    public static get environment(): Environment {
        if (process.env.NODE_ENV === "production") {
            if (process.env.REACT_APP_ENV === "production") {
                return envProd;
            } else if (process.env.REACT_APP_ENV === "stage") {
                return envStage;
            }
        } else if (process.env.NODE_ENV === "development") {
            return envDevelopment;
        }
        return envDevelopment;
    }

    public static get apiBaseUrl(): string {
        return AppConstants.environment.apiBaseUrl;
    }
    public static get forceRefreshIdToken(): boolean {
        return AppConstants.environment.forceRefreshIdToken;
    }
    // this will decide whether we have to use local function or deployed functions
    public static get useLocalFunction(): boolean {
        return false;
    }


    /**
     * meta tags
     */
    public static getMetaTag(pageName: PageNameEnum): MetaTag {
        switch (pageName) {
            case PageNameEnum.home:
                return {
                    title: '',
                    description: 'Your favourite platform to enjoy and learn coding',
                    url: '',
                    pageName: pageName
                };
            case PageNameEnum.topicList:
                return {
                    title: 'Topics',
                    description: 'Choose a topic to start reading your favourite article',
                    url: '/topic',
                    pageName: pageName
                };
            default:
                return {
                    title: PageNameEnum[pageName],
                    description: 'Your favourite platform to enjoy and learn coding',
                    url: '/tag',
                    pageName: pageName
                };
        }
    }


}


