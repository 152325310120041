import { IonAvatar, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRouterLink, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import HelmetWrapper from '../../../components/Core/Common/HelmetWrapper';
import QuizItem from '../../../components/quiz/QuizItem';
import { AppConstants, PageNameEnum } from '../../../constants/AppConstants';
import { getQuizList } from '../../../data/dataApi';
import { Quiz } from '../../../models/Quiz';
import './QuizList.scss';


interface OwnProps extends RouteComponentProps { }

interface StateProps {
    mode: 'ios' | 'md';

}

interface DispatchProps {
}

type QuizListPageProps = OwnProps & StateProps & DispatchProps;

const QuizList: React.FC<QuizListPageProps> = ({ history, mode }) => {
    const [quizList, setQuizList] = useState<Quiz[]>([]);
    let metaTag = AppConstants.getMetaTag(PageNameEnum.quizList);
    useIonViewDidEnter(async () => {
        metaTag = AppConstants.getMetaTag(PageNameEnum.quizList);
        const data = await getQuizList();
        setQuizList(data.responseData)
    })

    const pageRef = useRef<HTMLElement>(null);

    return (
        <IonPage ref={pageRef} id="quiz-list-page">
            <HelmetWrapper metaTag={metaTag} />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonRow>
                        <IonRouterLink routerLink="/">
                            <IonChip >
                                <IonAvatar>
                                    <img src="assets/icons/firealgo-144.png" alt="fa" />
                                </IonAvatar>
                                <IonTitle>FireAlgo</IonTitle>
                            </IonChip>
                        </IonRouterLink>
                        <IonTitle>Quiz List</IonTitle>
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonGrid>
                    <IonRow>
                        {quizList.map(quiz => (
                            <IonCol size="12" size-sm="6" size-lg="4" key={quiz.id}>
                                <QuizItem quiz={quiz}></QuizItem>
                            </IonCol>
                        ))}

                    </IonRow>
                </IonGrid>

            </IonContent>

        </IonPage>
    );

};

export default QuizList;

