import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, useIonViewDidEnter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import Menu from './components/Menu';
// import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './App.scss';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import QuizDetail from './pages/quiz/QuizDetail';
import QuizList from './pages/quiz/QuizList';
import TagList from './pages/TagList';
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
  useIonViewDidEnter(() => {
    console.log("useIonViewDidEnter event fired in App.tsx")
  })
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" component={Home} exact={true} />
            <Route path="/quiz" component={QuizList} exact={true} />
            <Route path="/quiz/:id" component={QuizDetail} exact={true} />
            <Route path="/tags" component={TagList} exact={true} />
            <Route component={PageNotFound} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
