import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

/* declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> { }
} */

export abstract class HttpClient {
    protected readonly instance: AxiosInstance;

    public constructor(baseURL: string) {
        this.instance = axios.create({
            baseURL,
        });

        this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleResponse = (response: AxiosResponse) => {
        console.log(response);
        return response.data;
    }

    protected _handleError = (error: AxiosError) => {
        console.error(error.toJSON());
        return Promise.reject(error);
    }
}