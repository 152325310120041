/**
 * Environment Variables
 * job is similar to environment.ts file 
 */
export interface Environment {
    production: boolean;
    apiBaseUrl: string;
    prodApiBaseUrl?: string;
    firebase: object;
    forceRefreshIdToken: boolean;
};
// Development (Local) Environment & pointing to project firealgo-dev
export const envDevelopment: Environment = {
    production: false,
    apiBaseUrl: "http://localhost:5001/firealgo-48da7/asia-south1",
    prodApiBaseUrl: "https://asia-south1-firealgo-dev-b796b.cloudfunctions.net",
    forceRefreshIdToken: false,
    firebase: {
        apiKey: "AIzaSyCrG2A4fSLKShjCJ-VrsFu9fAA4u0Z0_64",
        authDomain: "firealgo-48da7.firebaseapp.com",
        projectId: "firealgo-48da7",
        storageBucket: "firealgo-48da7.appspot.com",
        messagingSenderId: "346423974014",
        appId: "1:346423974014:web:87278e24bed5a4c78ce02f",
        measurementId: "G-RBR6TVXZK8"
    }
};
// Staging Env & pointing to project firealgo-dev
export const envStage: Environment = {
    production: false,
    apiBaseUrl: "https://asia-south1-firealgo-dev-b796b.cloudfunctions.net",
    forceRefreshIdToken: false,
    firebase: {
        apiKey: "AIzaSyC6DATL3MY-W-_JCDNIIcOHTzVgZZgjVTM",
        authDomain: "firealgo-dev-b796b.firebaseapp.com",
        databaseURL: "https://firealgo-dev-b796b.firebaseio.com",
        projectId: "firealgo-dev-b796b",
        storageBucket: "firealgo-dev-b796b.appspot.com",
        messagingSenderId: "613417136629",
        appId: "1:613417136629:web:e71367f28a296b610ef0bc",
    }
};

// Development (Local) Environment & pointing to project firealgo
export const envDevPointingProd: Environment = {
    production: false,
    apiBaseUrl: "http://localhost:5001/firealgo/us-central1",
    prodApiBaseUrl: "https://us-central1-firealgo.cloudfunctions.net",
    forceRefreshIdToken: false,
    firebase: {
        apiKey: "AIzaSyCrG2A4fSLKShjCJ-VrsFu9fAA4u0Z0_64",
        authDomain: "firealgo-48da7.firebaseapp.com",
        projectId: "firealgo-48da7",
        storageBucket: "firealgo-48da7.appspot.com",
        messagingSenderId: "346423974014",
        appId: "1:346423974014:web:87278e24bed5a4c78ce02f",
        measurementId: "G-RBR6TVXZK8"
    }
};

//Super Prod: Prod Environment & pointing to project firealgo
export const envProd: Environment = {
    production: false,
    apiBaseUrl: "https://asia-south1-firealgo-48da7.cloudfunctions.net",
    forceRefreshIdToken: false,
    firebase: {
        apiKey: "AIzaSyCrG2A4fSLKShjCJ-VrsFu9fAA4u0Z0_64",
        authDomain: "firealgo-48da7.firebaseapp.com",
        projectId: "firealgo-48da7",
        storageBucket: "firealgo-48da7.appspot.com",
        messagingSenderId: "346423974014",
        appId: "1:346423974014:web:87278e24bed5a4c78ce02f",
        measurementId: "G-RBR6TVXZK8"
    }
};
// Environment Variables::END

