import React from 'react';
import { } from '@ionic/react';
import { Helmet } from "react-helmet";
import { MetaTag } from '../../../../constants/AppConstants'

interface HelmetWrapperProps {

    metaTag: MetaTag;

}
const HelmetWrapper: React.FC<HelmetWrapperProps> = ({ metaTag }) => {
    const baseUrl = "https://www.firealgo.com";
    return (
        <>
            <Helmet>
                <title>{metaTag.title ? metaTag.title + " | Firealgo" : 'Firealgo'}</title>
                <meta name="description" content={metaTag.description ? metaTag.description : ''} />
                <link rel="canonical" href={baseUrl + metaTag.url} />
            </Helmet>
        </>
    )
}

export default HelmetWrapper;