// import { AxiosError } from 'axios';
// import { type } from 'os';

export const getIsOnline = (): boolean => {
    return navigator.onLine;
}
const INTERNET_FAILURE = "Internet Failure";
// const TIMEOUT_ERROR = "Timeout Error";


/**https://javascript.info/custom-errors
 * 
 */
export class HttpErrorResponse extends Error {
    status: number;
    url: string = "";
    isAxiosError: boolean;
    // message: string = "";
    constructor(message: string, status: number, isAxiosError: boolean, url?: string) {
        super(message); // (1)
        this.status = status;
        this.isAxiosError = isAxiosError;
        if (url) {
            this.url = url;
        }
    }
}

export const getInternetFailedError = (): Promise<never> => {
    const customError = new HttpErrorResponse(INTERNET_FAILURE, 1234, false);
    throw customError;
}
export interface ErrorObject {
    header: string;
    subHeader: string;
    message: string;
    type: 'NOINTERNET' | 'TIMEOUT' | 'SERVICEDOWN' | 'WRONGINPUT' | 'UNKNOWN';
}
export const defaultErrorObject: ErrorObject = {
    header: 'Error',
    subHeader: 'Unknown Error',
    message: 'Unknown Error Occured',
    type: "UNKNOWN"
}
export const handleApiError = (error: any): ErrorObject => {
    const err: ErrorObject = defaultErrorObject;
    if (error.status === 1234) {
        err.subHeader = 'NOINTERNET';
        err.type = "NOINTERNET";
        err.message = "Internet is Not Connected. Please check your Network Connection."
    } else {
        err.message = error.message;
        err.type = error.code;
        err.subHeader = error.code;
        if (error.message === 'Network Error') {
            err.type = 'SERVICEDOWN';
            err.subHeader = error.message;
            err.message = `Service is down and could be under maintanenace. Please try again later.`;
        }
    }
    return err;
}

/* export const handleApiError = async (error: HttpErrorResponse) => {
    let header = `Error`;
    let errMsg = 'Error';
    if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
        errMsg = error.error.message;
        // const customError = new HttpErrorResponse({ error: 1234 });
    } else {
        if (error.status === 1234 && error.error == 1234) {
            errMsg = "Internet is Not Connected. Please check your Network Connection.";
            // await this.presentAlert("Error", "Internet is Not Connected. Please check your Network Connection.");
        } else if (error.message == "Timeout has occurred") {
            errMsg = "Timeout has occurred.";
            // await this.presentAlert("Error", "Timeout has occurred.");
        } else if (error.status == 0 && error.statusText == "Unknown Error") {
            errMsg = `Service is down and could be under maintanenace. Please try again later.`;
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            header = `Error : ${error.status}`;
            errMsg = `${error.error.message}`;
            console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
        }
    }
    // await this.presentAlert(header, errMsg);
}; */

