import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { chevronBack, chevronForward, play, reloadOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { Slide, Zoom } from "react-awesome-reveal";
// import { Jumbotron } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import NotesCard from '../../../components/shared/NotesCard';
import { getQuizById } from '../../../data/dataApi';
import { Question, Quiz } from '../../../models/Quiz';
import './QuizDetail.scss';

/* export interface Note {
    value: number;
    type?: 'success' | 'warning' | 'danger';
} */

export interface QuizResult {
    quesCount: number;
    correctQIdtList: string[];
    wrongQIdList: string[];
    skippedQIdList: string[];
    score: number;
    scoreInPercentage: number;
    noteCardType: 'success' | 'warning' | 'danger';
    topRowColorClass?: string;
    grade?: 'poor' | 'average' | 'good' | 'very good' | 'excellent' | 'super champ';
}

interface OwnProps extends RouteComponentProps<{ id: string }> {
};

interface StateProps {
    mode: 'ios' | 'md';
}

interface DispatchProps {
}

type QuizDetailPageProps = OwnProps & StateProps & DispatchProps;

const QuizDetail: React.FC<QuizDetailPageProps> = ({ mode, match }) => {
    // let ques: Question | undefined;
    const defaultQuizResult: QuizResult = {
        quesCount: 0,
        correctQIdtList: [],
        wrongQIdList: [],
        skippedQIdList: [],
        score: 0,
        scoreInPercentage: 0,
        noteCardType: 'danger',
        grade: 'poor'
    };

    const [id, setId] = useState<string>();
    const [quiz, setQuiz] = useState<Quiz>();
    const [currentQuesIndex, setCurrentQuesIndex] = useState(0);
    const [isQuizStarted, setIsQuizStarted] = useState(false);
    const [selected, setSelected] = useState<string>();
    // const [totalScored, setTotalScored] = useState(0);
    const [isCorrect, setIsCorrect] = useState(false);
    const [isCheckAnswer, setIsCheckAnswer] = useState(false);
    const [isQuizEnd, setIsQuizEnd] = useState(false);
    const [result, setResult] = useState<QuizResult>(defaultQuizResult);
    const [showResultButton, setShowResultButton] = useState(false);
    const [disabledOptionList, setDisabledOptionList] = useState<string[]>([]);//list of answer text options

    const restartQUiz = () => {
        setIsQuizStarted(false);
        setCurrentQuesIndex(0);
        setSelected(undefined);
        setIsCorrect(false);
        setIsCheckAnswer(false);
        setIsQuizEnd(false);
        setShowResultButton(false);
        setDisabledOptionList([]);
        if (quiz) {
            setResult({ ...defaultQuizResult, quesCount: quiz.questions.length });
        }
    }
    // set of helper functions
    const isQuesAnsweredWrongAlready = (quesId: string): boolean => {
        const wrongQIdList = result.wrongQIdList;
        return wrongQIdList.includes(quesId);
    }

    const updateQuizResult = (quesId: string, isCorrect: boolean) => {
        if (isCorrect) {
            setResult({ ...result, correctQIdtList: [...result.correctQIdtList, quesId] });
        } else {
            setResult({ ...result, wrongQIdList: [...result.wrongQIdList, quesId] });
        }
    }
    const updateSkipQuesResult = (quesId: string) => {
        setResult({ ...result, skippedQIdList: [...result.skippedQIdList, quesId] });
    }
    const updateDisabledOptionList = (answerOptionText: string, disableAll?: boolean) => {
        if (quiz) {
            if (disableAll) {
                const list = quiz.questions[currentQuesIndex].answerOptions.map(e => e.answerText);
                setDisabledOptionList(list);

            } else {
                setDisabledOptionList([...disabledOptionList, answerOptionText]);
            }
        }
    }
    const calculateFinalResult = () => {
        const score = result.correctQIdtList.length;
        const scoreInPercentage = score * 100 / result.quesCount;
        const noteCardType = scoreInPercentage < 30 ? 'danger' : (scoreInPercentage >= 30 && scoreInPercentage < 60) ? 'warning' : 'success';
        setResult({
            ...result,
            score: score,
            scoreInPercentage: scoreInPercentage,
            noteCardType: noteCardType
        });
        setIsQuizEnd(true);
        console.log('quizResult', result);
    }

    useIonViewDidEnter(async () => {
        console.log('ionViewDidEnter event fired');
        const id = match.params.id;
        setId(id);
        console.log(id);

        // const quiz = await mainApiInstance.getQuiz(id);  //don't call below line outside the try block. 
        const data = await getQuizById(id);
        setQuiz(data.quiz);
        console.log("quiz", data);
        setResult({ ...result, quesCount: data.quiz.questions.length });
    });

    const showNextOrSkip = (isSkip?: boolean) => {
        if (quiz) {
            setDisabledOptionList([]);
            const ques: Question = quiz.questions[currentQuesIndex];
            const isAlreadyAnsweredFlag = isQuesAnsweredWrongAlready(ques.id);
            if (isSkip && !isAlreadyAnsweredFlag) {
                updateSkipQuesResult(quiz.questions[currentQuesIndex].id);
            }
            console.log("skipping or shownext index", currentQuesIndex);
            if ((quiz.questions.length - 1) > currentQuesIndex) {
                setCurrentQuesIndex(currentQuesIndex + 1);
                // reset values for the next questions
                setSelected(undefined);
                setIsCorrect(false);
                setIsCheckAnswer(false);
            } else {
                setShowResultButton(true);
            }
        }
    }
    const checkAnswer = () => {
        // now we have to evaluate the answer.
        console.log("checking answer for question index", currentQuesIndex);
        setIsCheckAnswer(true);
        if (quiz) {
            const ques: Question = quiz.questions[currentQuesIndex];
            const flag = isQuesAnsweredWrongAlready(ques.id);
            ques.answerOptions.forEach(a => {
                if (a.answerText === selected) {
                    if (a.isCorrect) {
                        setIsCorrect(true);
                        updateDisabledOptionList(a.answerText, true);
                        if (!flag) {
                            updateQuizResult(ques.id, true);
                        }
                        if (isLastIndex()) {
                            setShowResultButton(true);
                        }
                    } else {
                        setIsCorrect(false);
                        updateDisabledOptionList(a.answerText);
                        if (!flag) {
                            updateQuizResult(ques.id, false);
                        }
                    }
                }
            });
        }
    }

    const startQuiz = () => {
        setIsQuizStarted(true);
    }

    const isLastIndex = () => {
        if (quiz) {
            return (quiz.questions.length - 1) === currentQuesIndex;
        }
        return true;
    }

    const setCheckBoxSelection = (e: any) => {
        // e.target.name
        setSelected(e.detail.value);
    }
    const pageRef = useRef<HTMLElement>(null);
    // const ios = mode === 'ios';
    return (
        <IonPage ref={pageRef} id="quiz-detail">
            <IonHeader mode="ios">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/quiz" />
                    </IonButtons>
                    <IonTitle> {id + " Quiz"}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {/** This the first page when quiz has not started yet */}
                {quiz && quiz.questions && !isQuizStarted &&
                    <IonGrid>
                        <IonRow class="ion-justify-content-center ion-align-items-center quiz-page-row--first">
                            <Zoom>

                                <IonCol size="9" size-xl="6">
                                    <h1 className="mb">{id + " Quiz"}</h1>
                                    <div className="mb">
                                        <IonLabel>{quiz.questions.length} questions</IonLabel>
                                    </div>
                                    <IonButton onClick={startQuiz}>Start Quiz &nbsp;
                                        <IonIcon icon={play} />
                                    </IonButton>
                                </IonCol>
                            </Zoom>
                        </IonRow>
                    </IonGrid>
                }
                {/** Displays the Note card to notify the chosen answer is correct or wrong!*/}
                {quiz && quiz.questions && isQuizStarted && isCheckAnswer && !isQuizEnd &&
                    <IonGrid>
                        <IonRow class="ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" sizeLg="9" class="ion-padding-horizontal-sm">
                                {isCorrect &&
                                    <NotesCard isCenter={false} type='success' >This is <IonLabel color="primary">correct</IonLabel>!</NotesCard>
                                }
                                {!isCorrect &&
                                    <NotesCard isCenter={false} type='danger' >This is <IonLabel color="danger">wrong</IonLabel>!</NotesCard>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                }
                {/**The Qustion with answer options and buttons page. The main quiz */}
                {quiz && quiz.questions && isQuizStarted &&
                    <> {!isQuizEnd &&
                        <IonGrid>
                            <IonRow class="ion-justify-content-center  quiz-page-row--first">
                                <IonCol size="12" sizeLg="9" class="ion-padding-horizontal-sm">
                                    Question {currentQuesIndex + 1}:
                                    <h3>{quiz.questions[currentQuesIndex].questionText}</h3>
                                    <IonList lines='none'>
                                        <IonRadioGroup value={selected} onIonChange={e => setCheckBoxSelection(e)} name={quiz.questions[currentQuesIndex].id}>
                                            <IonListHeader>
                                                <IonLabel>Choose the correct option: </IonLabel>
                                            </IonListHeader>
                                            {quiz.questions[currentQuesIndex].answerOptions.map(answer => (
                                                <IonItem key={answer.answerText} class="mt-sm-3 border">
                                                    <IonLabel class="ion-text-wrap">{answer.answerText}</IonLabel>
                                                    <IonRadio slot="start" value={answer.answerText} disabled={disabledOptionList.includes(answer.answerText)} />
                                                </IonItem>
                                            ))}
                                        </IonRadioGroup>
                                    </IonList>
                                    {!isCorrect && !showResultButton &&
                                        <IonRow class="ion-justify-content-end">
                                            <IonButton onClick={e => showNextOrSkip(true)} fill='outline'>
                                                Skip question
                                            </IonButton>
                                            <IonButton onClick={checkAnswer} disabled={selected === undefined
                                            }> Check answer </IonButton>
                                        </IonRow>
                                    }
                                    {isCorrect && !isQuizEnd && !showResultButton &&
                                        <IonRow class="ion-justify-content-end">
                                            <IonButton onClick={e => showNextOrSkip(false)}>
                                                {"Next"}
                                                <IonIcon icon={chevronForward} />
                                            </IonButton>
                                        </IonRow>
                                    }
                                    {showResultButton &&
                                        <IonRow class="ion-justify-content-end">
                                            <IonButton onClick={e => calculateFinalResult()}>
                                                {"See result"}
                                                <IonIcon icon={chevronForward} />
                                            </IonButton>
                                        </IonRow>
                                    }
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    }
                        {/** The quiz result page */}
                        {isQuizEnd &&
                            <>
                                <IonGrid class="grid-full-width">
                                    <IonRow class={" ion-justify-content-center ion-align-items-center " + result.noteCardType}>
                                        <IonCol size="12" size-xl="6" class="ion-padding-horizontal">
                                            <h2>Here is your quiz result</h2>
                                            <p className="medium">{"You scored " + result.scoreInPercentage + " %"}</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>

                                <IonGrid class=".lt-space-regular">
                                    <Slide triggerOnce>
                                        <NotesCard isCenter type={result.noteCardType} >
                                            You scored <IonLabel color={result.noteCardType}>{result.score} </IonLabel> out of {quiz.questions.length} questions.
                                        </NotesCard>
                                        <NotesCard isCenter type='warning' >
                                            You skiped <IonLabel color='warning'>{result.skippedQIdList.length} </IonLabel>  Quesstions!.
                                        </NotesCard>
                                        <NotesCard isCenter type='danger' >
                                            You answered wrong <IonLabel color='danger'>{result.wrongQIdList.length} </IonLabel>  Quesstions!.
                                        </NotesCard>
                                    </Slide>
                                    <IonRow class="ion-justify-content-start">
                                        <IonButton href="/quiz" router-direction="back" fill='outline'>
                                            <IonIcon icon={chevronBack} />
                                            &nbsp; Back
                                        </IonButton>
                                        <IonButton onClick={e => restartQUiz()}>
                                            <IonIcon icon={reloadOutline} />
                                            &nbsp; Retry quiz
                                        </IonButton>
                                    </IonRow>
                                </IonGrid>
                            </>
                        }
                    </>
                }
            </IonContent>
        </IonPage >
    );
}
export default QuizDetail;
