import { IonAlert, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonToggle } from "@ionic/react";
import { helpCircle, homeOutline, logIn, logOut, moon, person, pricetag, pricetags } from "ionicons/icons";
import { FunctionComponent, useState } from "react";
import { useLocation } from "react-router";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser, setIsLoggedIn } from '../../data/User/UserSlice';
import './Menu.scss';
interface AppPages {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}
interface Route {
  [name: string]: AppPages[]
}

const routes: Route = {
  appPages: [
    { title: 'Home', url: '/', mdIcon: homeOutline, iosIcon: homeOutline },
    { title: 'Topic', url: '/topic', mdIcon: person, iosIcon: homeOutline },
    { title: 'Tags', url: '/tags', mdIcon: pricetags, iosIcon: pricetag },
    { title: 'Quiz', url: '/quiz', mdIcon: helpCircle, iosIcon: helpCircle },
  ],
  loggedInPages: [
    { title: 'testFunctions', url: '/testFunctions', mdIcon: logOut, iosIcon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', url: '/login', mdIcon: logIn, iosIcon: logIn }
  ]
}
interface MenuProps {

}

const Menu: FunctionComponent<MenuProps> = () => {
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark");
  };
  function renderlistItems(list: AppPages[]) {
    return list
      .filter(route => !!route.url)
      .map(appPage => (
        <IonMenuToggle key={appPage.title} autoHide={false}>
          <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
            <IonLabel>{appPage.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Firealgo.com</IonListHeader>
          <IonNote>Inspired by you!</IonNote>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList>
          <IonListHeader>Account</IonListHeader>
          {user.isLoggedin ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          {user.isLoggedin &&
            <IonItem button onClick={() => setShowAlert(true)}>
              <IonIcon slot="start" icon={logOut}></IonIcon>
              <IonLabel>Logout</IonLabel>
            </IonItem>}
          <IonItem>
            <IonIcon slot="start" icon={moon} className="component-icon component-icon-dark" />
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle
              slot="end"
              name="darkMode"
              onIonChange={toggleDarkModeHandler}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonItem>Version- March-2023</IonItem>
      </IonFooter>
      <IonAlert
        isOpen={showAlert}
        header="Are you sure you want to log out ?"
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: async () => {
              dispatch(setIsLoggedIn(false));
            }
          }
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
    </IonMenu>
  );
}

export default Menu;