import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

import { Tag } from '../../models/Tag';
import MainApi from '../core/mainApi';
export interface TagState {
    tags: Tag[];
    status: 'idle' | 'loading' | 'failed';

}

const initialState: TagState = {
    tags: [] as Tag[],
    status: 'idle'
}

export const fetchTagData = createAsyncThunk(
    'tag/fetchTagData',
    async () => {
        // const response = await fetchCount(amount);
        // // The value we return becomes the `fulfilled` action payload
        // return response.data;
        try {
            const tagList: any = await MainApi.getInstance().getTagList();
            return tagList.data;

        } catch (e) {
            console.log('error', e);
        }
    }
);

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<string>) => {
            state.status = 'loading'
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTagData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTagData.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload;
            })
            .addCase(fetchTagData.rejected, (state) => {
                state.status = 'failed';
            })
    },
})

export const { setIsLoading } = tagSlice.actions;
export const selectTag = (state: RootState) => state.tag;
export default tagSlice.reducer;