import React from 'react';
import { IonToolbar, IonContent, IonPage, IonHeader, useIonViewDidEnter, IonButtons, IonMenuButton, IonLabel, IonChip, IonIcon, IonRouterLink } from '@ionic/react';
import './PageNotFound.scss';
import { play } from 'ionicons/icons';

interface OwnProps { }
interface DispatchProps { }
type PageNotFoundProps = OwnProps & DispatchProps;

const PageNotFound: React.FC<PageNotFoundProps> = () => {

    useIonViewDidEnter(async () => {
        console.log('ionViewDidEnter event fired');
    });

    return (
        <IonPage id="pageNotFoundPage">

            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding" color="medium">
                <div className="fa-container">
                    <div className="central-body">
                        <img src="assets/img/404/404.svg" alt="img" className="image-404" />
                        <div className="home-btn">
                            <IonRouterLink routerLink="/">

                                <IonChip color="dark">
                                    <IonLabel>GO BACK HOME</IonLabel>
                                    <IonIcon icon={play} />
                                </IonChip>
                            </IonRouterLink>
                        </div>

                    </div>
                </div>
                <div className="objects">
                    <img className="object_rocket" src="assets/img/404/rocket.svg" width="40px" alt="rocket" />
                    <div className="earth-moon">
                        <img className="object_earth" src="assets/img/404/earth.svg" width="100px" alt="earth" />
                        <img className="object_moon" src="assets/img/404/moon.svg" width="80px" alt="moon" />
                    </div>
                </div>
                <div className="glowing_stars">
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                    <div className="star"></div>
                </div>
            </IonContent>

        </IonPage>
    );

};

export default PageNotFound;
