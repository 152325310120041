import { isPlatform } from '@ionic/react';

export const getConfig = () => {
    if (isPlatform('hybrid')) {
        return {
            backButtonText: 'Previous',
        };
    }

    return {
        // menuIcon: 'ellipsis-vertical',
    };
};

// setupIonicReact(getConfig());