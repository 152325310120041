import { getFunctions, httpsCallable } from 'firebase/functions';

import { AppConstants, REGION } from '../constants/AppConstants';
import { CallableReqData } from '../interfaces/CallableReqData';
import { Quiz } from '../models/Quiz';
import * as commonService from './core/commonService';
import MainApi from './core/mainApi';
import { Preference } from './User/state';
import { app } from '../firebase';
// import { getApp } from "firebase/app";

const functions = getFunctions(app, REGION);
const quizDataUrl = '/assets/data/quiz.json';

export const getQuizList = async () => {
  const response = await Promise.all([
    fetch(quizDataUrl),
  ]);
  const responseData: Quiz[] = await response[0].json();
  const data = {
    responseData
  }
  return data;
}
export const getQuizById = async (id: string) => {
  const quizList = await getQuizList();
  const quizFileter: Quiz[] = quizList.responseData.filter(x => x.id === id);
  return { quiz: quizFileter[0] };
}
/** TOPIC API START
 * NOTE-we are not using these api . We are using MainApi and axios for any api call
 */
export const getTopicList = async () => {
  const url = `${AppConstants.apiBaseUrl}/topic`;
  const response = await Promise.all([
    fetch(url),
    MainApi.getInstance().getTopicList()

  ]);
  const responseData = await response[0].json();
  console.log(responseData)
  console.log('data using axios', response[1]);
  if (responseData.statusCode !== 200) {
    return { topics: [] };
  }
  const data = {
    topics: responseData.data
  }
  return data;
}
export const getTopicById = async (id: string): Promise<any> => {

  if (commonService.getIsOnline()) {
    const url = `${AppConstants.apiBaseUrl}/topic/id/${id}`;
    const response = await Promise.all([
      fetch(url)
    ]);
    const responseData = await response[0].json();
    console.log(responseData)
    return responseData;

  } else {
    commonService.getInternetFailedError();
  }

}
// Topic API END HERE


export const getUserData = async () => {
  const response = await Promise.all([
    getItemFromStorage('hasLoggedIn'),
    getItemFromStorage('hasSeenTutorial'),
    getItemFromStorage('username'),
    getItemFromStorage('darkmode'),
  ]);
  const isLoggedin = response[0] === 'true';
  const hasSeenTutorial = response[1] === 'true';
  const username = response[2] || undefined;
  const darkMode = response[3] === 'true';
  const preference: Preference = {
    hasSeenTutorial,
    darkMode
  }
  const data = {
    isLoggedin,
    username,
    preference
  }
  return data;
}

type StorageKey = 'hasLoggedIn' | 'hasSeenTutorial' | 'username' | 'darkmode' | 'idToken';
export const setItemToStorage = (key: StorageKey, value?: string) => {
  if (!value) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}
export const getItemFromStorage = (key: StorageKey) => {
  try {
    return localStorage.getItem(key);
  } catch (ex) {
    return '';
  }
}

export const getUserDetails = async () => {
  try {
    const userFn = httpsCallable(functions, 'user');
    const data: CallableReqData = {
      body: {},
      method: 'GET',
      action: 'GET_BY_ID'
    }
    const result = await userFn(data);//POST https://us-central1-firealgo-dev.cloudfunctions.net/testOnCall
    console.log(result);
    return result;

  } catch (error: any) {
    // In case we are not logged in or text value is empty or not passed
    console.log(error);
    const err = {
      code: error.code,
      message: error.message,
      details: error.details
    }
    console.error(err)
  }
}
export const addTagsToUserApi = async (tags: string[]) => {
  try {
    const userFn = httpsCallable(functions, 'user');
    const data: CallableReqData = {
      body: {
        tags: tags
      },
      method: 'PUT',
      action: 'UPDATE'
    }
    const result = await userFn(data);//POST https://us-central1-firealgo-dev.cloudfunctions.net/testOnCall
    console.log(result);
    return result;

  } catch (error: any) {
    // In case we are not logged in or text value is empty or not passed
    console.log(error);
    const err = {
      code: error.code,
      message: error.message,
      details: error.details
    }
    console.error(err)
    throw error;
  }
}

