import { IonAvatar, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRouterLink, IonRow, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { FunctionComponent, useCallback, useState } from 'react';
import './TagList.scss';


import { RouteComponentProps } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TagListItem from '../../components/TagListItem';
import { fetchTagData, selectTag } from '../../data/Tag/TagSlice';
import { addOrRemoveTagWrapper, selectUser } from '../../data/User/UserSlice';


interface TagListProps extends RouteComponentProps {

}

const TagList: FunctionComponent<TagListProps> = ({ history }) => {

    const tagState = useAppSelector(selectTag);
    const user = useAppSelector(selectUser);
    const userTags = user.tags;
    const dispatch = useAppDispatch();
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isTagAdded, setIsTagAdded] = useState(false);

    useIonViewDidEnter(() => {
        console.log('ionViewDidEnter  event fired');
        dispatch(fetchTagData());
    });



    const handleShowToast = useCallback((message: string, isTagAddedFlag: boolean) => {
        setToastMessage(message);
        setIsTagAdded(isTagAddedFlag);
        setShowCompleteToast(true);
    }, []);
    console.log(tagState.tags)
    return (<IonPage id="tag-list-page">
        {Header()}
        <IonContent>
            <IonToast
                isOpen={showCompleteToast}
                message={toastMessage}
                duration={500}
                color={isTagAdded ? "primary" : "danger"}
                onDidDismiss={() => setShowCompleteToast(false)}
                mode="ios"
            />
            <IonGrid>
                <IonRow class="first-row">
                    <IonCol>
                        <h1>Follow the tags that matters you the most.</h1>

                    </IonCol>
                </IonRow>
                <IonRow>
                    {tagState && tagState.tags && tagState.tags.length > 0 && tagState.tags.map(tag => (
                        <IonCol size="12" size-sm="6" size-lg="4" key={tag.id}>
                            <TagListItem
                                tag={tag}
                                isUserTag={userTags.indexOf(tag.id) > -1}
                                userTags={userTags}
                                OnaddOrRemoveTagWrapper={addOrRemoveTagWrapper}
                                isAuthenticated={user.isLoggedin}
                                history={history}
                                handleShowToast={handleShowToast}
                            />
                        </IonCol>
                    ))}

                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>);

    function Header() {
        return <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonRow>
                    <IonRouterLink routerLink="/">
                        <IonChip>
                            <IonAvatar>
                                <img src="assets/icons/firealgo-144.png" alt="fa" />
                            </IonAvatar>
                            <IonTitle>FireAlgo</IonTitle>
                        </IonChip>
                    </IonRouterLink>
                    <IonTitle>Tag List</IonTitle>
                </IonRow>
            </IonToolbar>
        </IonHeader>;
    }
}

export default TagList;



